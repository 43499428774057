import React from 'react';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import Header from './Components/Sections/Header';
import Welcome from './Components/Sections/Welcome';
import About from './Components/Sections/About';
import WhySolar from './Components/Sections/WhySolar';
import Services from './Components/Sections/Services';
import Contact from './Components/Sections/Contact';
import Footer from './Components/Sections/Footer';
import Youtube from './Components/Sections/Youtube';

import './App.css';


function App() {

// Import the functions you need from the SDKs you need

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

  return (
    <div className="App">
    <Youtube />
    <div className=''>
     <Header />
     <Welcome />
     <About />
     </div>
     <WhySolar />
     <Services />
     <Contact />
     <Footer />
    </div>
  );
}

export default App;
