
import './About.css';

function About() {
    return (
      <div className="About-section">
          <div className="">
            <h1 style={{}}>Innovative Energy Network Inc</h1>
            <h4>is one of the fastest growing solar installation company in Los Angeles. Providing you with quality craftsmanship is our top priority. We take pride in our work and strive to provide optimal customer service.</h4>
        </div>
      </div>
    );
  }
  
  export default About
;