function Footer() {

  const year = new Date().getFullYear();

    return (
      <div className="Footer-section">
        <p className="text-muted">© {year } Innovative Energy Network, All rights reserved.</p>
      </div>
    );
  }
  
  export default Footer;