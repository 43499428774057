import React, {useState, useEffect} from "react";
import ReactPlayer from 'react-player'

function Youtube() {

  const [browserWidth, setBrowserWidth] = useState(window.innerWidth)
  const [videoWidth, setVideoWidth] = useState('66vw')
 
 
 
  const updateDimensions = () => {
    setBrowserWidth(window.innerWidth);

    if(browserWidth <= '670'){
      setVideoWidth('78vw')
      console.log("browser set to 78w");
    }else if(browserWidth >= '671'){
      setVideoWidth('66vw');
    }

  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);



    return (



      <div className="Youtube-section">
    <ReactPlayer 
        className="" 
        url='https://www.youtube.com/embed/S_B-PXuL8S4' 
        width='100%'
        height='66vw' 
        wrapper="div" 
        loop= {true}
        playing={true}
            muted={true} />
          <div className="rs-fullvideo-cover"></div>
        </div>
    );
  }
  
  export default Youtube;