import React, {useState, useEffect} from "react";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFace} from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faLinkedin } from '@fortawesome/free-brands-svg-icons'


import './Contact.css';

function Contact() {
  const [browserWidth, setBrowserWidth] = useState(window.innerWidth)

  const updateDimensions = () => {
    setBrowserWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);


    return (
      <div id="Contact" className="" style={{backgroundColor: 'white'}}>
         
        <div className="precontact">
        
        </div>
       
       <div className="Contact-section">
       <h1 style={{textDecoration: 'underline'}}>CONTACT US</h1>
        <div className='contactLeft'>
       <p className="text-left">Phone: (805) 915-7576</p>
       <p className="text-left">Email: info@innovativeenergynetwork.com</p>
       </div>
      {browserWidth <= 454 ? null : <div className="vr"></div>}
      {browserWidth <= 454 ? <br /> : null}
       <div className='contactLeft'>
       <div>
       <p className="media-links"><FontAwesomeIcon icon={faFacebookSquare} className="solar-green cardIcon"/></p>
       <p className="media-links"><FontAwesomeIcon icon={faLinkedin} className="solar-green cardIcon"/></p>
       <p className="text-left ">{}</p>
       </div>
       <p className="text-left">Coming Soon!</p>
       </div>
      
       </div>
       
       {/* <div>
       <h1 style={{fontSize: '24px'}}>When you're ready to make a change let us know.</h1>
        </div> */}
      
      </div>
    );
  }
  
  export default Contact;