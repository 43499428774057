import React from "react";
import {Container, Card, Row} from 'react-bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileInvoiceDollar, faEarthAmerica, faBolt} from '@fortawesome/free-solid-svg-icons';
import './WhySolar.css';

library.add(faFileInvoiceDollar);

function WhySolar() {

function GetIcon (){
   return (<FontAwesomeIcon icon={faFileInvoiceDollar} className="solar-green"/>)
}


    return (
      <div id="WhySolar" className="WhySolar-section">
        <h1 className="exo-font">WHY GO SOLAR?</h1>
        <Row style={{justifyContent: 'space-evenly', marginRight: '0px'}}>
        <Card style={{ width: '18rem', border: 'none'}}>
        <h1 style={{fontSize: 72}}><FontAwesomeIcon icon={faBolt} className="solar-green cardIcon"/></h1>
  <Card.Body>
    <Card.Title>You have the Power!</Card.Title>
    <Card.Text>
    Electricity costs across the country have risen by 15% over the past ten years, and this is a trend that’s
likely to continue. This is the time to prepare your home for the future and pay less for power. Once you
go solar, you will begin to save money instantly.
    </Card.Text>
  </Card.Body>
</Card>
<Card  style={{ width: '18rem', border: 'none' }}>
<h1 style={{fontSize: 72}}><FontAwesomeIcon icon={faEarthAmerica} className="solar-green cardIcon"/></h1>
  <Card.Body>
    <Card.Title> Provides clean, renewable energy</Card.Title>
    <Card.Text>
    Home solar is a clean, emissions-free, and renewable energy source. Unlike fossil fuels such as coal and natural gas, home solar doesn’t release harmful pollutants or greenhouse gas emissions—like carbon dioxide—into the air and water supply.
    </Card.Text>
  </Card.Body>
</Card>
<Card style={{ width: '18rem', border: 'none' }}>
<h1 style={{fontSize: 72}}><FontAwesomeIcon icon={faFileInvoiceDollar} className="solar-green cardIcon"/></h1>
  <Card.Body>
    <Card.Title >Increases home value</Card.Title>
    <Card.Text>
    Across the U.S., solar panels raise a home’s value by 4.1% on average. That means a home valued at $500,000 could get a $20,500 increase.
    Specific numbers vary between each installation and property, yet recent studies show an average increase in resale value between $4,020 and $5,911 for each kilowatt of solar panels installed
    </Card.Text>
  </Card.Body>
</Card>
        </Row>
      </div>
    );
  }
  
  export default WhySolar;