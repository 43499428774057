import React, {useState, useEffect} from "react";
import {Nav, Container, Navbar} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faLeaf, faFileInvoiceDollar} from '@fortawesome/free-solid-svg-icons';
import ienLogo from '../Images/ienLogosmall.png';
import './Header.css';

// import 'bootstrap/dist/css/bootstrap.min.css';

function Header() {
const [browserWidth, setBrowserWidth] = useState(window.innerWidth)

const updateDimensions = () => {
  setBrowserWidth(window.innerWidth);
}
useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);
}, []);

    return (
      <div className="Header-section">
<Navbar collapseOnSelect style={{margin: '0'}} expand="lg" bg="opaque" className="narBarColor" variant="dark">
  <Container style={{margin: '0', width: '100%'}} fluid>
  <Navbar.Brand>
  {/* <FontAwesomeIcon icon={faLeaf} className="solar-green" /> */}
  {/* <img src={ienLogo} alt="ien-logo" style={{width: '72px'}}></img> */}
    <h1 > <img src={ienLogo} alt="ien-logo" style={{width: '40px' }}></img>  {browserWidth <= 767 ? `IEN` : `Innovative Energy Network`}</h1>
  </Navbar.Brand>
  <Navbar.Toggle aria-controls="responsive-navbar-nav" />
  <Navbar.Collapse id="responsive-navbar-nav">
    <Nav className="me-auto">
     
    </Nav>
    <Nav>
    <Nav.Link href="#About" className="text-shadow">About us</Nav.Link>
      <Nav.Link href="#WhySolar" className="text-shadow">Why go Solar?</Nav.Link>
      <Nav.Link href="#Services" className="text-shadow">Services</Nav.Link>
      <Nav.Link href="#Contact" className="text-shadow">Contact</Nav.Link>
    </Nav>
  </Navbar.Collapse>
  </Container>
</Navbar>
      </div>
    );
  }
  
  export default Header;