import React from "react";
import { Image } from "react-bootstrap";
import PanelImage from '../Images/PanelImage.jpg';
import './Welcome.css';


function Welcome() {
    return (
      <div className="">
      {/* <Image src={PanelImage}  fluid  /> */}
        {/* <img src={PanelImage} alt="Solar Panels" className="Welcome-image"/> */}
        <div className="empty-bed"></div>
        <div className="Welcome-text">
        {/* <h1 className="title" style={{color: 'white', textShadow: '1px 1px black'}}>Welcome to Innovative Energy Network</h1>  */}
        <div className="empty-bed"></div>
        
        
        </div>
      
      </div>
    );
  }
  
  export default Welcome;