import React, {useState, useEffect} from "react";
import {Container, Card, Row, Carousel} from 'react-bootstrap'
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFileInvoiceDollar, faEarthAmerica, faBolt} from '@fortawesome/free-solid-svg-icons';
import panelLift from '../Images/panelLift.jpg';
import Monitoring from '../Images/Monitoring.jpg';
import greenLand from '../Images/greenLand.jpg';

// import './Services.css';


function Services() {

const [browserWidth, setBrowserWidth] = useState(window.innerWidth)

const updateDimensions = () => {
  setBrowserWidth(window.innerWidth);
}
useEffect(() => {
  window.addEventListener("resize", updateDimensions);
  return () => window.removeEventListener("resize", updateDimensions);
}, []);

    return (
      <div id='Services' className="Services-section" style={{position: 'relative', zIndex: '7', boxShadow: '0 0 5px 0 black'}}>
        <div>
        <Carousel fade controls={false} indicators={false}>
  <Carousel.Item>
    <img
      className="d-block w-100"
      src={panelLift}
      alt="First slide"
    />
    <Carousel.Caption style={browserWidth <= '437' ? {width: '100%', left:'0', position: 'relative', color: 'white'} : {color: 'white'}}>
      <h3 className="text-shadow2" style={browserWidth <= '513' ? {display: 'none'} : null}>Allow us to do the heavy lifting!</h3>
      <p className={browserWidth <= 436 ? 'text-shadow3' : 'text-shadow2'}>All of our technicians are highly trained and with over 10 years of combined experience, you are sure to
get the best quality workmanship delivered to you. Our teams work with the aesthetic outcome in mind,
while equally ensuring your solar panels are installed safely and correctly.</p>

    </Carousel.Caption>
  </Carousel.Item>  
</Carousel>
        </div>
      </div>
    );
  }
  
  export default Services;